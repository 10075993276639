import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IpAddressService {
  private apiUrl = 'https://api.radar.io/v1/geocode/ip';

  constructor(private http: HttpClient) {}

  getIpAddressInfo(): Observable<any> {
    const authKey = environment.radarAPIKey;
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': authKey })
    };

    return this.http.get(this.apiUrl, httpOptions);
  }
}