export class Profile {
	orgtypeid:string;
    userid: string;  
    email: string;  
    password: string;    
    firstname:string;
	lastname:string;
	contactno:string;
	orgname:string;
	website:string;
	countrycode:string;
	country_Id:string;
    billingAddress1: string;
	billingAddress2: string;
	billingCityid: string;
	billingZipid: string;
    billingCountryid: string;
	billingStateid: string;
	shippingAddress1: string;
	shippingAddress2: string;
	shippingCityid: string;
	shippingZipid: string;
	shippingCountryid: string;
	shippingStateid: string;
}

