import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Profile } from './profile';
import { CookieService } from 'src/app/shared/services/cookie-service';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })


  export class ProfileService {
    
    constructor(private http: HttpClient,private _cookie:CookieService) { 

    
    }  
  
    UpdateProfile(profile:Profile): Observable<Profile> { 
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
      return this.http.put<Profile>(environment.apiUrl + '/client/user/update',  
      profile, httpOptions);  
    }  
    GetProfile(userId:string): Observable<Profile> { 
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
      return this.http.get<Profile>(environment.apiUrl + '/client/user/'+userId,  
       httpOptions);  
    }  
    GetOrganizationList(): Observable<[]> { 
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
      return this.http.get<[]>(environment.apiUrl + '/global/master/organizationTypes',  
     httpOptions);  
    } 
    ChangeEmail(userId:string, _email:string,_otp:Number): Observable<object> {
      const obj = {"userid":userId,"email":_email,"otp":_otp};
      
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
      return this.http.put<object>(environment.apiUrl + '/client/user/update',  
      obj, httpOptions);  
    } 
    ChangePassword(oldpassword:string,newpassword:string): Observable<object> {
      const user=this._cookie.GetCookies('loggeduser');
      const obj = {"userid":user.userid,"password":newpassword,"oldpassword":oldpassword};
      
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
      return this.http.put<object>(environment.apiUrl + '/client/user/update/password',  
      obj, httpOptions);  
    } 

    GetAllCountryList(): Observable<string> { 
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
      return this.http.get<string>(environment.apiUrl + '/global/master/ur/countrylist', httpOptions);  
    } 
    GetAllSortedCountryList(): Observable<string> {
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };
      return this.http.get<string>(environment.apiUrl + '/global/master/ur/countrylistsorted',
       httpOptions);
    }
    GetAllStateList(_countryId): Observable<string> { 
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
      return this.http.get<string>(environment.apiUrl + '/global/master/ur/statelist/'+_countryId,  
       httpOptions);  
    }  
    GetAllCityList(_countryId: any,_stateName: any): Observable<string> { 
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
      return this.http.get<string>(environment.apiUrl + '/global/master/ur/citylist/'+_countryId + '/'+_stateName,  
       httpOptions);  
    }
    GetAllZipList(dataModel: any): Observable<string>{
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
      return this.http.post<string>(environment.apiUrl + '/global/master/ur/postalcodes', dataModel,  
       httpOptions); 
    }
    sendEmailVerifyLink(emailval): Observable<[]> {
      const params = {
        "email": emailval
      }
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      return this.http.post<[]>(environment.apiUrl + '/client/user/verify/email', params, httpOptions);
    }
  }
  

